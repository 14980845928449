<template>
  <div class="statistics">
    <img class="statistics-bg" src="~assets/images/user/statistics-bg.png" alt="" style="display: block">
    <div class="statistics-bg-data" @click="goLink(add[0].type,'1')">
      <div class="statistics-bg-data-num">{{add[0].number}}</div>
      <div class="statistics-bg-data-pep">{{add[0].title}}</div>
    </div>
    <div class="statistics-con">
      <div class="statistics-wrap">
        <div class="statistics-list-t">
          <div class="statistics-title">新手任务</div>
          <div class="statistics-list flex-wrap">
            <div class="statistics-item" @click="goLink(item.type,'1')" v-for="(item, index) in config" :key="index">
              <div class="item-icon"></div>
              <div class="item-main">
                <div class="item-tit">
                  <div>{{item.title}}</div>
                  <img class="statistics-more-icon"  src="~assets/images/user/statistics-more-icon.png" alt="">
                </div>
                <div class="item-num">{{item.number}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="statistics-list-c">
          <div class="statistics-title">任务包</div>
          <div class="statistics-list flex-wrap">
            <div class="statistics-item" @click="goLink(item.type,'1')" v-for="(item, index) in pageage" :key="index">
              <div class="item-icon"></div>
              <div class="item-main">
                <div class="item-tit">
                  <div>{{item.title}}</div>
                  <img class="statistics-more-icon" v-if="item.type != 'freshers'" src="~assets/images/user/statistics-more-icon.png" alt="">
                </div>
                <div class="item-num">{{item.number}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="statistics-list-b">
          <div class="statistics-title">限时任务</div>
          <div class="statistics-list flex-wrap">
            <div class="statistics-item" @click="goLink(item.type,'2')" v-for="(item, index) in limit" :key="index">
              <div class="item-icon"></div>
              <div class="item-main">
                <div class="item-tit">
                  <div>{{item.title}}</div>
                  <img class="statistics-more-icon" v-if="item.type != 'freshers'" src="~assets/images/user/statistics-more-icon.png" alt="">
                </div>
                <div class="item-num">{{item.number}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return{
      add:[
        {
          title: '新增人数',
          type: 'add',
          number: ''
        }
      ],
      config: [
        {
          title: '未做',
          type: 'freshers',
          number: ''
        }
      ],
      pageage:[
        {
          title: '未做',
          type: 'unfinished',
          number: ''
        },
        {
          title: '已做',
          type: 'finished',
          number: ''
        },
        {
          title: '未审核',
          type: 'audit',
          number: ''
        },
        {
          title: '已审核',
          type: 'audited',
          number: ''
        }
      ],
      limit:[
        {
          title: '未做',
          type: 'unfinished',
          number: ''
        },
        {
          title: '已做',
          type: 'finished',
          number: ''
        },
        {
          title: '未审核',
          type: 'audit',
          number: ''
        },
        {
          title: '已审核',
          type: 'audited',
          number: ''
        }
      ], // 数据导航配置
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 获取数据
    getData() {
      let url = this.$api.groupAll
      this.$http.get(url, true).then(res =>{
        if(res.data.success){
          let info = res.data.data
            this.add[0].number= info.members
            this.config[0].number= info.freshers
            this.pageage[0].number= info.package_members_n
            this.pageage[1].number= info.package_members_y
            this.pageage[2].number= info.package_tasks_n
            this.pageage[3].number= info.package_tasks_y
            this.limit[0].number= info.limit_tasks_n
            this.limit[1].number= info.limit_tasks_y
            this.limit[2].number= info.limit_checks_n
            this.limit[3].number= info.limit_checks_y
        }else {
          this.$router.replace('/user')
        }
      })
    },
    //  路由跳转
    goLink(type,val) {
      if(type == 'add') {
        // 新增人数统计
        this.$router.push({path: '/team/stat/add'})
      }else if(type == 'unfinished') {
        // 未完成的
        this.$router.push({path: '/team/stat/unfinished',query: {
            diff: val
          }})
      }else if(type == 'finished') {
        // 已完成的
        this.$router.push({path: '/team/stat/finished',query: {
            diff: val
          }})
      }else if(type == 'audit') {
        // 未审核
        this.$router.push({path: '/team/stat/audit',query: {
            diff: val
          }})
      }else if(type == 'audited') {
        // 已审核
        this.$router.push({path: '/team/stat/audited',query: {
            diff: val
          }})
      }else if(type == 'freshers') {
        // 已审核
        this.$router.push({path: '/team/stat/freshers',query: {
            diff: val
          }})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/team-data/statistics.scss";
</style>
